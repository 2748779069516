@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500&family=Poppins:wght@400;600&display=swap");

.login-wrapper {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.411);
  box-sizing: content-box;
  font-family: "Fira Sans", sans-serif;
}

.login-inner {
  width: 360px;
  background-color: #192337;
  padding: 48px;
}

.login-inner form {
  width: 100%;
  color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 12px;
}

.submit-btn {
  background: #1e9bf5;
  color: white;
  font-weight: 600;
  cursor: pointer;
  padding: 13px 16px;
  font-size: 16px;
  border-radius: 2px;
  margin-top: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;

}

.signup {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: #489BEE;
  width: fit-content;
  margin: 22px auto 0;
}

.spinner-wrapper {
  justify-content: center;
}

input {
  width: 100%;
}

a {
  color: dodgerblue;
}

input:focus {
  outline: none;
}

.auth-error {
  margin-top: 8px;
  color: red;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.modal-header {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 32px;
  .modal-title {
    margin-left: 16px;
  }
}

