.HHWall {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;
  padding: 2em;
  min-height: 100vh;
  background: #2c3248;
}
.HHWall article {
  background: #f5f6fa;
  max-height: 33vh;
  display: flex;
  flex-direction: column;
}
.HHWall article figure {
  flex: 1;
  margin: 0;
  display: block;
  background: #f5f6fa;
}
.HHWall h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0.25em 0.5em;
  font-size: 1.4em;
  color: #2c3248;
}
.HHWall h2 span {
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  background: #a8b1cf;
  border-radius: 0.25em;
  padding: 0.2em 0.4em;
}
.Wall__video video {
  width: 100%;
  max-width: 100%;
}
.HHWall input {
}
.Wall__hls {
  display: flex;
  align-items: center;
  padding: 0.5em;
  background: #f5f6fa;
}
.Wall__hls input {
  flex: 1;
  margin: 0 0.5em;
}
.Wall__hls button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
  text-transform: uppercase;
  color: #2c3248;
  padding: 2px 5px;
  transition: background-color 0.1s, color 0.1s;
}
.Wall__hls button:focus,
.Wall__hls button:active {
  background: #1e9bf5;
  color: white;
}
.Wall__hls button:focus img,
.Wall__hls button:active img {
  background: white;
  border-radius: 5px;
}
.Wall__video figure {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Wall__video figure figcaption {
  margin: 2.5em;
  color: #666;
  text-align: center;
}
