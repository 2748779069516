.time-panel {
  /* position: absolute; */
  /* top: 40px; */
  /* right: 456px; */
  width: fit-content;
  margin: 0 auto;
  z-index: 3;
  background: rgba(25, 35, 55, 0.75);
  display: flex;
  align-items: center;
  cursor: auto;
  padding: 12px 16px;
}
.time-panel.mobile {
  top: 40px;
}
.time-panel__time span {
  font-style: normal;
  font-size: 11px;
  color: #f5f6fa;
  opacity: 0.6;
  text-transform: uppercase;
  display: block;
  margin-bottom: 2px;
}
.time-panel__time time {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #f5f6fa;
}
.time-panel--old {
  background: rgba(111, 117, 143, 0.75);
}
.time-panel pre {
  margin: 0.88em 1em;
  font-size: 13px;
}
.time-panel button {
  display: inline-block;
  text-transform: uppercase;
  background: #1e9bf5;
  color: white;
  font-weight: 600;
  cursor: pointer;
  padding: 6px 12px;
  margin-left: 20px;
  font-size: 13px;
}
.time-panel button:active {
  opacity: 0.5;
}
