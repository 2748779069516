@import '~stream-chat-react/dist/scss/v2/index.scss';

.str-chat__main-panel-inner {
  height: 100vh;
}

.str-chat__list::-webkit-scrollbar {
  width: 3px;
}

.str-chat__list::-webkit-scrollbar-track {
  background: transparent;
}

.str-chat__list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 5px solid transparent;
  opacity: 0;
  transition: opacity 0.3s;
}

.chasers-wrapper:hover::-webkit-scrollbar-thumb {
  opacity: 1;
  background-color: rgba(111, 117, 143, 0.4);
}

.str-chat__header-livestream-left--title {
  color: white;
}

.str-chat {
  --str-chat__background-color: transparent;
  --str-chat__channel-header-background-color: transparent;
  --str-chat__message-input-background-color: transparent;
  --str-chat__message-textarea-color: white;
}

.str-chat__thread-container {
  background: transparent;
}

.str-chat__thread-header {
  background: transparent !important;
  color: white !important;
}

.str-chat__thread-container .str-chat__thread-header .str-chat__close-thread-button svg path {
  fill: white !important;
}

.str-chat__file-input-label,
.str-chat__file-input-container {
  display: none !important;
}

.timestamp .str-chat__message-simple-timestamp {
  display: none;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
  top: 9px !important;
}

.custom-menu-item {
  font-family: 'Fira Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.hidden {
  display: none;
}

.str-chat__reverse-infinite-scroll.str-chat__message-list-scroll {
  padding-right: 0 !important;
}