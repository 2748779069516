.str-chat__message-input.custom-input .str-chat__message-textarea-container {
  border-radius: 0;
  background: rgb(108, 119, 148, 0.1);
  border: none;
  height: 56px;
}

.str-chat__message-input.custom-input .str-chat__send-button {
  border-radius: 0;
  width: 66px;
  height: 56px;
  background: #489bee;
  opacity: 1;

  &.disabled {
    background: rgb(108, 119, 148, 0.1);
    opacity: 1;
  }
}

.str-chat__message-input.custom-input .str-chat__send-button.disabled svg path {
  fill: #6c7794;
}

.str-chat__message-input.custom-input .str-chat__send-button svg path {
  fill: white;
}

.str-chat__message-input.custom-input .str-chat__send-button svg {
  width: 22px;
}

.str-chat__message-input
  .str-chat__message-input-inner
  .str-chat__message-textarea-container
  .str-chat__message-textarea-with-emoji-picker
  .str-chat__message-textarea-emoji-picker
  .str-chat__message-textarea-emoji-picker-container {
  position: absolute;
  inset: auto 9px 8px auto;

  .emoji-mart {
    // padding: 8px;
    background: #323c5a !important;
    border: none;
    border-radius: 0;
  }
}

.emoji-mart-search,
.emoji-mart-scroll {
  padding: 0 16px !important;
}

.bordered {
  border: 1px solid #489bee !important;
}

.str-chat__message-textarea-container {
  height: 47px;
}

.str-chat__message-input .str-chat__message-input-inner {
  border: 1px solid transparent;
  border-radius: 2px;
}

.str-chat__input-flat-emojiselect {
  margin-right: -16px;
  margin-top: 3px;
  &:hover {
    cursor: pointer;
    svg {
      fill: #e8ebf2;
    }
  }
  svg {
    fill: #6c7794;
    zoom: 0.9
  }
}

.str-chat__message-input
  .str-chat__message-input-inner
  .str-chat__message-textarea-container
  .str-chat__message-textarea-with-emoji-picker
  .str-chat__message-textarea-emoji-picker {
  width: 5px !important;
  height: 5px !important;
}

.emoji-mart-anchor,
.emoji-mart-emoji {
  background: transparent !important;
}

.emoji-mart-search-icon {
  display: none;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  display: none !important;
}

.emoji-mart-anchor-bar {
  display: none;
}

.emoji-mart-search {
  input {
    color: white;
    background: #192337;
    border-radius: 2px !important;
    border: none !important;
    padding: 9px 25px 8px 10px !important;
    margin: 12px 0;
  }
}

.emoji-mart-bar {
  border-bottom: 1px solid #6c77947e !important;
}

.str-chat__message-input .str-chat__message-textarea-container .str-chat__message-textarea {
  font-family: 'Fira Sans', sans-serif !important;
}
