.banner {
  background: #1C4165;
  padding: 8px;
  max-width: 208px;
  margin: 6px auto 0;
  width: 100%;
}

.banner.mobile {
  margin: 6px auto 0;
}

.banner.collapsed {
  background: none;
  padding: 0;
  margin: 16px auto;
}
.banner.collapsed.mobile {
  margin-top: 16px;
  margin-bottom: 8px;
}
.banner.collapsed a {
  display: block;
  margin: 0 auto;
}
.banner.collapsed a:nth-child(2) {
  margin-top: 6px;
  margin-left: 2px;
}

.banner_header {
  display: flex;
  align-items: center;
}
.banner_header .logo {
  margin-right: 8px;
}

.banner_header h1 {
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  color: #ADBDCC;
  font-weight: 500;
  margin: 0;
}

.banner_text {
  font-weight: 600;
  font-size: 11px;
  line-height: 1.2;
  margin-bottom: 12px;
  color: #FFFFFF;
}

.banner_app-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link--get-app {
  display: block;
  text-align: center;
  text-decoration: none;
  background: #1E9BF5;
  height: 32px;
  line-height: 32px;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  transition: 0.3s;
}
.link--get-app:hover {
  background: #5fa7ee;
}
