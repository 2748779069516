.notification-wrapper {
  display: flex;
  font-size: 14px;
  color: white;
  font-weight: 500;
  background-color: #6c7794;
  padding: 16px;
  align-items: center;
  line-height: 19.6px;
}

.bottom-btn {
  padding: 8px 16px;
  position: absolute;
  bottom: 65px;
  z-index: 100;
  width: 100%;
}

.icon {
  margin-right: 16px;
}

.new-message-inner {
  cursor: pointer;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #E8EBF2;
  font-size: 14px;
  display: flex;
  width: fit-content;
  background-color: #489BEE;
  margin: 0 auto;
  align-items: center;

  .bottom-icon {
    margin: 12px 13px 7px;
  }

  .new-message-text {
    padding: 12px 16px 12px 0;
  }
}

