.message-wrapper {
  display: flex;
  font-size: 14px;
  color: white;
  font-weight: 500;
  background-color: rgba(28, 65, 101, 1);
  padding: 16px 8px;
  align-items: center;
  line-height: 19.6px;
  margin-bottom: 4px;
  border-radius: 2px;
  margin-left: -8px;
  margin-right: 6px;
}
