.str-chat__message-options {
  display: flex !important;
}

.str-chat__message--other .str-chat__message-inner {
  width: 100%;
}

.str-chat__message--me .str-chat__message-inner {
  width: 100%;
}

.str-chat__message.str-chat__message--me > .str-chat__message-sender-avatar,
.str-chat__quoted-message-preview.str-chat__message--me > .str-chat__message-sender-avatar {
  display: block;
}

.str-chat__message.str-chat__message--me,
.str-chat__quoted-message-preview.str-chat__message--me {
  grid-template-areas:
    'avatar message'
    '. replies'
    '. translation-notice'
    '. metadata';
  column-gap: var(--str-chat__spacing-2);
  grid-template-columns: auto 1fr;
  justify-items: start;
}

.str-chat__message.str-chat__message--me .str-chat__message-inner,
.str-chat__quoted-message-preview.str-chat__message--me .str-chat__message-inner,
.str-chat__message.str-chat__message--other .str-chat__message-inner,
.str-chat__quoted-message-preview.str-chat__message--other .str-chat__message-inner {
  width: 100%;
  grid-template-areas:
    'message-bubble options'
    'reactions reactions';
}

.str-chat__message--me .str-chat__message-inner {
  margin-inline-start: auto;
}

.str-chat__message-simple-status.str-chat__message-status {
  display: none;
}

.str-chat__message-inner {
  grid-area: message;
  display: grid;
  grid-template-areas:
    'message-bubble options'
    'reactions reactions';
  grid-template-columns: auto 1fr;
  column-gap: var(--str-chat__spacing-2);
  position: relative;
}

.message-wrapper-content {
  color: white;
  display: flex;
}

.message-header {
  display: flex;
  flex-direction: column;
}

.str-chat__message-simple__actions.str-chat__message-options button {
  color: white;
}

.str-chat__message .str-chat__message-bubble {
  background: transparent;
  color: white;
}

.str-chat__message .str-chat__message-bubble {
  color: white !important;
  background: transparent !important;
}

.str-chat__li--middle .str-chat__message > .str-chat__message-sender-avatar,
.str-chat__li--top .str-chat__message > .str-chat__message-sender-avatar {
  visibility: visible;
}

.str-chat__message.str-chat__message--me,
.str-chat__quoted-message-preview.str-chat__message--me,
.str-chat__message.str-chat__message--other,
.str-chat__quoted-message-preview.str-chat__message--other {
  display: grid;
  grid-template-areas:
    'avatar metadata'
    '. replies'
    '. message';
}

.str-chat__message-simple-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
}

.str-chat__message-simple-timestamp {
  font-size: 14px;
}

.str-chat__message .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text,
.str-chat__quoted-message-preview
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text {
  padding: 4px 0;
}

.str-chat__message .str-chat__message-inner .str-chat__message-options,
.str-chat__quoted-message-preview .str-chat__message-inner .str-chat__message-options {
  flex-direction: row;
}

.str-chat__message .str-chat__message-inner .str-chat__message-options,
.str-chat__quoted-message-preview .str-chat__message-inner .str-chat__message-options {
  width: 100%;
}

.str-chat__li {
  padding: 6px 0;
  margin-bottom: 4px;
}

.str-chat__message-text-inner.str-chat__message-simple-text-inner {
  font-size: 14px;
  font-weight: 400;
}

.str-chat * {
  font-family: 'Fira Sans', sans-serif;
}

.str-chat__message .str-chat__message-inner .str-chat__message-options,
.str-chat__quoted-message-preview .str-chat__message-inner .str-chat__message-options {
  margin-top: -28px;
}

.str-chat__attachment-list .str-chat__message-attachment--image,
.str-chat__attachment-list .str-chat__message-attachment-card--header,
.str-chat__attachment-list .str-chat__message-attachment--video {
  border-radius: 0;
}

.str-chat__attachment-list {
  border: 1px solid #6c7794;
}

.str-chat__attachment-list .str-chat__message-attachment-card {
  min-width: none;
  margin: 0;
}

.str-chat__message-attachment-card--source-link {
  a {
    color: #e8ebf2;
    font-weight: 500;
    font-size: 14px;
  }
}

.str-chat__message-attachment-card--title {
  color: #e8ebf2;
  font-weight: 400;
  font-size: 14px;
}

.str-chat__message-attachment-card--text {
  color: #6c7794;
  font-weight: 400;
  font-size: 14px;
}

.str-chat__message .str-chat__message-inner .str-chat__message-bubble .str-chat__message-text p,
.str-chat__quoted-message-preview
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text
  p {
  a {
    color: #6c7794;
  }
}

.str-chat__message--me .str-chat__message-reactions-container {
  justify-content: start;
}

.str-chat__message-reaction {
  border-radius: 2px !important;
  background: #323c5a !important;

  .str-chat__message-reaction-count {
    color: #e8ebf2;
  }
}

.str-chat__main-panel-inner,
.str-chat__list {
  outline: none;
}

.str-chat__message .str-chat__message-inner .str-chat__message-options,
.str-chat__message.str-chat__message--me .str-chat__message-inner .str-chat__message-options,
.str-chat__message.str-chat__message--other .str-chat__message-inner .str-chat__message-options {
  padding-right: 20px;
}


.str-chat__message .str-chat__message-options .str-chat__message-reactions-button:hover {
  background-color: transparent;
  cursor: pointer;
  color: white;
  .str-chat__message-action-icon path {
    fill: white;
  }
}

.str-chat__message .str-chat__message-options .str-chat__message-actions-box-button:hover {
  background-color: transparent;
  .str-chat__message-action-icon path {
    fill: white;
  }
}

.str-chat__message .str-chat__message-inner .str-chat__message-options,
.str-chat__message.str-chat__message--me .str-chat__message-inner .str-chat__message-options,
.str-chat__message.str-chat__message--other .str-chat__message-inner .str-chat__message-options {
  justify-content: end;
  flex-direction: row-reverse;
}

.str-chat__message-actions-box {
  border-radius: 2px;
  background-color: #323c5a;
}

.str-chat__message-actions-box
  .str-chat__message-actions-list
  .str-chat__message-actions-list-item-button {
  font-family: 'Fira Sans', sans-serif;
}

.str-chat__message-actions-box .str-chat__message-actions-list-item-button:hover {
  background-color: transparent;
}

.str-chat__reaction-selector .str-chat__message-reactions-options {
  border-radius: 2px;
  background-color: #323c5a;
}

.str-chat__reaction-selector
  .str-chat__message-reactions-options
  .str-chat__message-reactions-option {
  background-color: #323c5a;
  &:hover {
    background-color: transparent;
  }
}

.str-chat__message .str-chat__message--deleted-inner {
  background-color: transparent;
  font-size: 13px;
}

.str-chat__message-actions-box:not(
    .str-chat__message-actions-box-angular
  ).str-chat__message-actions-box--mine {
  inset-block-end: 100%;
  inset-inline-start: -600%;
}

.str-chat__message-reaction-selector {
  inset-block-end: 150%;
}

.str-chat__reaction-selector
  .str-chat__message-reactions-options
  .str-chat__message-reactions-option.str-chat__message-reactions-option-selected {
  background-color: #273047;
}

.str-chat__message .str-chat__message-sender-avatar,
.str-chat__avatar-image.str-chat__avatar-image--loaded {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
}

.delete-text {
  color: #6c7794;
  font-style: italic;
  font-size: 14px;
}
