:root {
  --lightGrey2: #e8ebf2;
  --lightGrey3: #f5f6fa;
  --skyBlue2: #1e9bf5;
}

.chaser {
  position: relative;
}
.chaser.tracking {
  z-index: 2;
}

.ChaserMarker {
  z-index: 1;
}
.ChaserPin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.ChaserPin--live path {
  fill: #c52a15;
}
.ChaserPin--playing path {
  fill: white;
}
.Avatar {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.ChaserPin--live .Avatar img {
  border: 3px solid #ff553e;
}
.Avatar img,
.Avatar .Avatar__blank {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  display: block;
  border: 2px solid #6f758f;
}
.Avatar__blank {
  background: #a8b1cf;
}
.FakeAvatar,
.Avatar .FakeAvatar {
  color: #e8ebf2;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.FakeAvatar {
  font-size: 1.1em;
}
.ChaserPin > span {
  color: white;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  background: #ff553e;
  display: block;
  width: auto;
  position: absolute;
  bottom: 10px;
  margin-top: auto;
  padding: 2px 5px;
  border-radius: 2px;
}

.HHMap {
  position: relative;
  width: 100vw;
  height: 100%;
  z-index: 999;
}

.mapboxgl-marker {
  z-index: 1;
}

.HHPanel {
  flex-shrink: 0;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #15192f;
  background: #2c3248;
  z-index: 2;
  padding: 0 20px;
  color: #f5f6fa;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.02em;
}
.HHPanel h2 {
  margin: 0.2em 0 0 0;
  font-size: 32px;
}

.HHPanel dt {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #a8b1cf;
  opacity: 0.75;
}
.HHPanel dd {
  margin: 0;
  padding: 0;
}

.HHMarker {
  position: relative;
}
.HHMarker svg + img {
  object-fit: cover;
  border-radius: 14px;
  position: absolute;
  top: 3px;
  left: 3px;
}
.HHPanel dl > div {
  margin: 0 0 20px 0;
}
.HHPanel dl > div:not(:first-child) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.HHPanel dd {
  display: flex;
  align-items: center;
}
.HHPanel dd svg {
  order: 2;
  margin-left: 20px;
}
@media (min-width: 1024px) {
  .HHPanel {
    padding: 40px 7.5% 32px;
  }
  .HHPanel h2 {
    margin: -0.2em 0 0 0;
  }
  .HHPanel dl {
    display: flex;
  }
  .HHPanel dl > div:first-child {
    width: 25%;
  }
  .HHPanel dt {
    margin: 0 0 1em 0;
  }
  .HHPanel dl > div:not(:first-child) {
    display: block;
    margin-bottom: 0;
  }
  .HHPanel dl > div {
    margin-right: 7.5%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .HHPanel dd {
    display: block;
  }
  .HHPanel dd svg {
    margin-right: 16px;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-left: 0;
  }
}

.Button {
  display: flex;
  align-items: center;
  border: 1px solid #1e9bf5;
  color: #1e9bf5;
  background: none;
  padding: 0.5em 1em;
  margin: 0 1em;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}
.Button svg {
  margin-right: 1em;
}

video:focus,
video:active {
  outline: none;
}
/*.Livestreams video::-webkit-media-controls-timeline,
.Livestreams video::-webkit-media-controls-current-time-display,
.Livestreams video::-webkit-media-controls-timeline-container,
.Livestreams video::-webkit-media-controls-time-remaining-display,
!* Todo: figure out the classes here, so we don't have to wrap .Live (that's a bad class name anyways *!
.Live video::-webkit-media-controls-timeline,
.Live video::-webkit-media-controls-current-time-display,
.Live video::-webkit-media-controls-timeline-container,
.Live video::-webkit-media-controls-time-remaining-display,
.LiveStreamPanel:not(.replay) video::-webkit-media-controls-timeline,
.LiveStreamPanel:not(.replay) video::-webkit-media-controls-current-time-display,
.LiveStreamPanel:not(.replay) video::-webkit-media-controls-timeline-container,
.LiveStreamPanel:not(.replay) video::-webkit-media-controls-time-remaining-display {
  display: none;
}*/

.HHMarker figure {
  background: #606980;
}
.HHMarker figcaption {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  /* identical to box height */
  color: white;
  text-align: center;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 0.5em;
}
.HHMarker--update:hover .Update__meta {
  display: block;
}
.Update__pin {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.Update__pin--selected svg path.pin-bg,
.Update__pin--selected .Update__pin__video path {
  fill: white;
}

.Update__pin__video {
  position: absolute;
  top: 11px;
  left: 13px;
}

.Update__pin__video path {
  fill: #1e9bf5;
  stroke: #192337;
  stroke-width: 1px;
}

.UpdatePanel {
  max-height: 100vh;
  padding: 108px 16px 0 16px;
}

.HHMap--replay .UpdatePanel {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.Update__meta {
  max-height: 100%;
  position: relative;
  background: var(--lightGrey3);
  pointer-events: auto;
  flex: 1 0 auto;
}

.Update__meta:not(.Update__media) {
  width: 100%;
  min-width: 230px;
  max-width: 96%;
}

@media (min-width: 500px) {
  .Update__meta:not(.Update__media) {
    width: 400px;
  }
}

@media (min-width: 800px) {
  .Update__meta:not(.Update__media) {
    width: 450px;
  }
}
@media (min-width: 1024px) {
  .Update__meta:not(.Update__media) {
    margin-top: 0;
    width: 550px;
  }
}
@media (min-width: 1350px) {
  .Update__meta:not(.Update__media) {
    width: 650px;
  }
}
@media (min-width: 1600px) {
  .Update__meta:not(.Update__media) {
    width: 700px;
  }
}

.Update__meta.Update__media {
  max-width: 85vw;
}
@media (min-width: 640px) {
  .Update__meta.Update__media {
    max-width: 60vw;
  }
}
@media (min-width: 1024px) {
  .Update__meta.Update__media {
    min-width: 270px;
    max-width: 40vw;
  }
}

.UpdatePanel:not(.LiveStreamPanel) .Update__meta {
  overflow-y: auto;
}
.Update__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.Update__controls {
  display: flex;
  justify-content: space-between;
}
.Update__controls button {
  height: 40px;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;
}
.Update__controls button:not([disabled]) {
  cursor: pointer;
}
.Update__controls button:not([disabled]):hover {
  background: var(--lightGrey2);
}
.Update__controls button svg path {
  transition: fill 0.2s;
}

.Update__controls button:not([disabled]):hover svg path {
  fill: var(--skyBlue2);
}

.Update__golive {
  border: 1px solid #f5553f;
  color: #f5553f;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  padding: 6px 12px 6px;
  font-size: 13px;
  align-self: center;
  margin-right: auto;
}

.Update__close {
  display: block;
  height: 32px;
  width: 32px;
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: 9;
  cursor: pointer;
}

.Update__meta .Update__chaser {
  padding: 8px 16px 16px;
}

.Update__close img {
  display: block;
}
.Update__meta figure {
  margin: 0;
  display: block;
}
.Update__meta figure img {
  display: block;
  max-width: 100%;
}
.Update__meta .Video {
  position: relative;
}
.Update__meta .Video video {
  width: 100%;
  display: block;
}
.video-info {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 15px;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 18%);
  background: rgb(0 0 0 / 8%);
  border-radius: 6px;
}
.video-info span {
  margin: 0 8px;
}

.media-wrapper video,
.media-wrapper img {
  margin: 0 auto;
}

.media-wrapper video {
  max-height: calc(85vh - 100px);
  background: black;
}

.media-wrapper img {
  width: 100%;
  max-height: calc(85vh - 100px);
  object-fit: cover;
}

@media (min-width: 1024px) {
  .Update__meta--text,
  .Update__meta--attachment {
    max-width: 33vw;
  }
  .Update__controls button {
    height: 48px;
    width: 48px;
  }
}
.Update__meta figcaption {
  background: var(--lightGrey3);
  padding: 1em;
}
.Update__meta figcaption p {
  max-width: 320px;
  word-break: break-word;
}
.Update__meta figcaption:empty {
  display: none;
}
.Update__text {
  color: #343e5c !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.Sidebar {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  z-index: 999;
}

.Chat {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  z-index: 999;
}

.Livestreams {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  background: #192337;
  padding: 2px 16px 5px;
  width: 240px;
  position: relative;
  pointer-events: auto;
  flex-shrink: 0;
}

.Livestreams.Chat:has(.str-chat__thread-container.str-chat__thread) {
  width: 700px;
}

.Livestreams.Chat {
  padding: 2px 5px 5px;
  width: 440px;
  .Livestreams__arrow-wrapper {
    color: red;
    flex-direction: row-reverse;
  }
}

.Livestreams.Chat:not(.Livestreams--hidden) {
  padding: 0;
  padding-bottom: 5px;
}

.HHMap--replay .Sidebar {
  z-index: 11;
  max-height: 100%;
}

.Livestreams--hidden:not(.Chat) {
  padding: 2px 4px 5px;
  width: 48px;
}
.Livestreams--hidden.Chat {
  width: 130px;
  height: 51px;
}
.Livestreams--hidden.Chat .chasers-wrapper {
  opacity: 0;
}

.chatTitle {
  display: none;
}

.Livestreams--hidden.Chat .chatTitle {
  display: block;
  rotate: -90deg;
  width: fit-content;
  height: fit-content;
  margin: auto;
  color: white;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.Livestreams button {
  background: #192337;
  border-radius: 0;
}

.Livestreams.mobile {
  padding-top: 52px;
}
.Livestreams.Livestreams--hidden.mobile {
  padding-top: 54px;
  position: relative;
}

.Livestreams--hidden.mobile::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 91px;
  background: #192337;
  border-bottom-right-radius: 6px;
}

.Livestreams.mobile .Livestreams__title {
  margin-bottom: 7px;
}

.Livestreams__title {
  margin-top: 8px !important;
  margin-bottom: 8px;
}

.Livestreams-list .Livestreams__title {
  padding-top: 11px;
}

.Livestreams header + .Livestreams__title,
.banner + .Livestreams__title {
  margin-top: 11px !important;
}

.Livestreams--hidden .Livestreams-list,
.Livestreams--hidden.mobile .Livestreams__title {
  margin-bottom: 0;
}

/* Navigation arrow start */
.Livestreams__arrow-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.Livestreams--hidden .Livestreams__arrow-wrapper {
  margin-left: 0;
}
.Livestreams--hidden .Livestreams__arrow-wrapper {
  margin-right: 0;
}

.Livestreams--hidden .Livestreams__arrow {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.Livestreams--hidden .Livestreams-chat__arrow {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-57%);
}

.Livestreams__arrow-hint {
  font-family: 'Fira Sans', sans-serif;
  color: #adbdcc;
  font-size: 13px;
  margin-top: 2px;
}

.Livestreams--hidden .Livestreams__arrow-hint {
  margin: 0px 8px 0;
}

.Livestreams__arrow,
.Livestreams-chat__arrow {
  cursor: pointer;
  width: 49px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Livestreams-chat__arrow {
  margin-left: 3px;
}

.navigation-arrow {
  transform: scale(1, 1);
  transition: 0.3s;
}
.navigation-arrow.closed {
  transform: scale(-1, 1);
}
.navigation-arrow-chat {
  transform: scale(-1, 1);
  transition: 0.3s;
}

.navigation-arrow-chat.closed {
  transform: scale(1, 1);
}
/* Navigation arrow end */

.Livestreams__logo {
  display: block;
}
.Livestreams--hidden .Livestreams__logo {
  display: none;
}
.Livestreams h4 {
  color: white;
  padding: 5px 5px 5px 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 4px;
}
.Livestreams--hidden h4 {
  display: none;
}
.Livestreams h4:not(:first-of-type) {
  opacity: 0.6;
}
.Livestreams header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Livestreams.Chat:not(.Livestreams--hidden) {
  header {
    justify-content: start;
    border-bottom: 1px solid #6c779440;
  }
}

.chat-title__text {
  margin-right: 6px;
}

.chat-title {
  margin-left: 102px;
  color: white;
}

.Livestreams--hidden header {
  justify-content: center;
  margin-top: 14px;
}
.Livestreams h2 {
  font-family: Fira Sans Condensed, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white;
  margin: 0;
}
.Livestreams--hidden .title-text {
  display: none !important;
}

.Livestreams--hidden .dots-wrapper {
  display: none !important;
}

.title-text {
  margin-left: 92px;
  color: white;
  margin-right: 6px;
}

.person-img {
  margin-top: 2px;
}

.user-counter {
  color: white;
  margin-left: 4px;
}

.Livestreams header > div:last-child {
  display: flex;
  align-items: center;
}

.chasers-wrapper {
  overflow-y: auto;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: rgba(111, 117, 143, 0.4) transparent;
}

.chat-wrapper {
  overflow-y: auto;
  width: 100%;
}

.Livestreams--hidden.Chat .chat-wrapper {
  opacity: 0;
}

.chasers-wrapper::-webkit-scrollbar {
  width: 3px; /* width of the entire scrollbar */
}
.chasers-wrapper::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
.chasers-wrapper::-webkit-scrollbar-thumb {
  border-radius: 8px; /* roundness of the scroll thumb */
  border: 5px solid transparent; /* creates padding around scroll thumb */
  opacity: 0;
  transition: opacity 0.3s;
}
.chasers-wrapper:hover::-webkit-scrollbar-thumb {
  opacity: 1;
  background-color: rgba(111, 117, 143, 0.4); /* color of the scroll thumb */
}

.Live__stream {
  position: relative;
}
.Live__chaser {
  z-index: 2;
  display: flex;
  padding: 5px 5px 5px 0;
  border-radius: 0.5em;
  cursor: pointer;
  max-width: 240px;
  text-decoration: none;
}
.Live__chaser--offline {
  align-items: center;
}
.Live__chaser--offline .FakeAvatar,
.Live__chaser--offline > img,
.Live__chaser--offline p:first-child {
  opacity: 0.5;
}

.Live__chaser p a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}
.Live__chaser:not(.Live__chaser--offline) p a {
  margin-top: 1em;
}
.Live__chaser--tracking {
  background: rgb(162, 170, 193, 0.15);
  cursor: default;
  border-radius: 1px;
}
.Live__chaser img,
.Live__chaser .FakeAvatar,
.Update__chaser .FakeAvatar {
  margin-right: 0.5rem;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  object-fit: cover;
  border: 2px solid #6f758f;
}
.Live__chaser--offline img,
.Live__chaser--offline .FakeAvatar {
  border: none;
}
.Update__chaser .FakeAvatar {
  margin-right: 15px;
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.Live__chaser .FakeAvatar {
  font-size: smaller;
}
.Live__chaser--live img {
  border: 2px solid #ff553e;
}
.Live__chaser--live {
  position: relative;
}
.Live__chaser--live:after {
  position: absolute;
  top: 50%;
  right: 16px;
  content: '';
  display: block;
  height: 6px;
  width: 6px;
  margin-top: -3px;
  border-radius: 3px;
  background: #ff553e;
}
.Live__chaser--live p + p {
  padding-right: 24px;
}

.Livestreams--hidden .Live__chaser {
  padding: 5px;
}

.Livestreams--hidden .Live__chaser img,
.Livestreams--hidden .Live__chaser .FakeAvatar {
  margin: 0 auto;
}
.Livestreams--hidden .Live__chaser--live:after {
  display: none;
}
.Live__chaser p {
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: #e8ebf2;
  margin: 0;
}
.Live__chaser p + p {
  font-weight: 400;
  color: #a2aac1;
}

.HHMap--replay {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.HHMap--replay .HHReplay {
  position: relative;
  height: auto !important;
  flex: 1;
}
.Panel {
  flex: 0;
}
.Timeline {
  flex-shrink: 0;
  position: relative;
  background: #192337;
  color: white;
  min-height: 50px;
  padding: 32px;
}
.Timeline__updates {
  width: 100%;
  height: 14px;
  padding: 0 0;
  position: relative;
}
.Timeline__update {
  background: #1e9bf5;
  position: absolute;
  height: 6px;
  width: 6px;
  margin-left: -3px;
  border-radius: 3px;
  z-index: 2;
  cursor: pointer;
}
.Timeline__update--live {
  background: #ff553e;
  margin-left: -2px;
}
.Timeline__scubber {
  width: 100%;
  height: 20px;
  background: #2c3248;
  position: relative;
  border: 0;
  border-top: 6px solid #192337;
  border-bottom: 8px solid #192337;
  cursor: pointer;
}
.Timeline__progress {
  height: 6px;
  background: #1e9bf5;
  position: absolute;
  left: 0;
}
.Timeline__progress--live {
  background: #ff553e;
}
.Timeline__hover {
  height: 6px;
  background: #1e9bf5;
  opacity: 0.25;
  position: absolute;
  left: 0;
}
.Timeline__rabbit {
  height: 12px;
  width: 12px;
  display: inline-block;
  position: absolute;
  right: -6px;
  top: -3px;
  border-radius: 6px;
  background: #1e9bf5;
  pointer-events: none;
}
.Rabbit {
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 8px;
  background: #1e9bf5;
  border: 2px solid white;
  box-shadow: 0 0 0 2px #15192f;
}
@media (min-width: 1024px) {
  .Timeline {
    padding: 32px 64px;
  }
}

.weather-radar_btn {
  position: absolute;
  left: 256px;
  bottom: 54px;
  cursor: pointer;
  color: #fff;
}
.weather-radar_btn.collapsed {
  left: 66px;
}
.weather-radar_btn.disabled {
  color: #5ab9ff;
}
.weather-radar_btn.disabled rect {
  fill: rgba(145, 155, 185, 0.5);
}

.weather-radar_text {
  position: absolute;
  left: 256px;
  bottom: 16px;
  font-weight: 500;
  font-size: 11px;
  color: #fff;
  margin-bottom: 0;
}
.weather-radar_text.collapsed {
  left: 66px;
}

.mapboxgl-ctrl-logo {
  position: absolute;
  bottom: 28px;
  left: 246px;
  margin-left: 0 !important;
}

.mapboxgl-ctrl {
  position: absolute;
  bottom: 8px;
  right: 440px;
  margin-left: 0 !important;
}

.mapboxgl-ctrl-logo.collapsed {
  left: 56px;
}

.mapboxgl-ctrl.mobile {
  right: 16px;
}

.mapboxgl-ctrl.collapsed {
  right: 8px;
}

.time-panel.collapsed {
  right: 111px;
}

.time-panel.mobile {
  right: 16px;
}

.chatTitle {
  color: white;
}

.emoji-mart-category-label span {
  background-color: transparent !important;
  color: white;
}

.emoji-mart {
  background: #192337 !important;
}

.str-chat__message-textarea-emoji-picker-container {
  transform: translate(0px, -45px) !important;
}

.dots-wrapper {
  display: flex;
  flex-grow: 1;

  button {
    margin-left: auto;
  }
}

.dots-svg:hover {
  cursor: pointer;

  path {
    fill: white;
  }
}

.header-wrapper {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600;

}

.logout-wrapper {
  button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: white;
    background: #323c5a;
    font-weight: 600;
    padding: 10px 12px;
    font-size: 16px;
  }
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: transparent !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
  background: #323c5a !important;
  border-radius: 2px !important;
  color: white !important;
  box-shadow: none !important;
}

@media (max-width: 500px) {
  .Livestreams.Chat:not(.Livestreams--hidden) {
    width: 100%;
  }
}

.opacity-none {
  opacity: 0;
}