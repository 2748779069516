.bounds {
  position: fixed;
  bottom: 36px;
  right: 450px;
  z-index: 2;
  background: #515869;
  padding: 8px;
  transition: background-color 0.2s;
  &.collapsed {
    right: 16px;
  }
}

.bounds--set {
  background: #1e9bf5;
}

.bounds svg,
.bounds button {
  display: block;
  cursor: pointer;
}
